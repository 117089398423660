<template>
  <div>
    <Breadcrumbs>
      {{ $route.meta.title }}
    </Breadcrumbs>
    <v-img
      src="@/assets/images/tokyu-vacations-bg-2.jpg"
      lazy-src="@/assets/images/lazy-img.png"
      position="center right"
      alt="tokyu_vacations"
    >
      <RoomAvailability
        :visible="isVisibleRoomAv"
        @close="closeRoomAvailability"
        @confirm="newBookSearch"
      />
    </v-img>
    <Exchange
      :visible="isVisibleExchange"
      @commitWhole="isVisibleSearch = true"
      @close="isVisibleExchange = false"
    />
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs'
import RoomAvailability from '@/views/VacancySearch/components/RoomAvailability'
import Exchange from '@/views/VacancySearch/components/Exchange'
import { ProductTypeNameToId } from '@/constants/product'

export default {
  name: 'VacancySearch',
  components: {
    Breadcrumbs,
    RoomAvailability,
    Exchange
  },
  async mounted () {
    await this.$doLoading(async () => {
      await this.$showGqlError(async () => {
        this.vsContract = await this.$store.dispatch('myVsContract', {
          apollo: this.$apollo
        })
        await this.$store.dispatch('loadContracts')
        await this.$store.dispatch('loadRemainingPoints')

        this.$store.commit('setDateRestriction', {
          canCheckInUntil: this.vsContractForBooking.canCheckInUntil,
          canCheckOutUntil: this.vsContractForBooking.canCheckOutUntil
        })
      })
    })
  },
  computed: {
    bookingList () {
      return this.$store.getters.getContractsForBooking.filter(c => c.productTypeId !== this.mwProductId)
    },
    mwProductId () {
      return ProductTypeNameToId.MW
    },
    mwBookingList () {
      return this.$store.getters.getContractsForBooking.filter(c => c.productTypeId === this.mwProductId)
    },
    canCheckInUntil () {
      return ''
    },
    canCheckOutUntil () {
      return ''
    },
    vsContractForBooking () {
      return this.bookingList.find(c => c.id === this.vsContract.id)
    }
  },
  data () {
    return {
      isVisibleRoomAv: true,
      isVisibleExchange: false,
      isVisibleSearch: false,
      vsContract: null
    }
  },
  methods: {
    async closeRoomAvailability () {
      this.isVisibleRoomAv = false
      await this.$router.push({ name: 'home' })
    },
    newBookSearch ({ checkInDate, checkOutDate }) {
      this.toNewBooking(this.vsContractForBooking)
      this.$store.commit('setCheckInDate', checkInDate)
      this.$store.commit('setCheckOutDate', checkOutDate)
      this.$router.push({ name: 'home-vacancy-search-reservation' })
    },
    toNewBooking (contract) {
      this.$store.commit('setContractIdForNewBooking', contract.id)
      this.isVisibleRoomAv = true
    }
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/styles/modules/variables-scss'as v;

::v-deep.v-image {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: var(--color__primary);
    opacity: 0.6;
  }

  .v-responsive__content {
    position: relative;
    z-index: 2;
  }

  .v-btn {
    border-radius: 39px !important;
    border: 1px solid var(--color__light-gray);
    box-shadow: 0 3px 6px rgba(v.$color__black, 0.435) !important;
  }

  .line-through {
    margin-bottom: 8px;
    text-align: center;
    font-weight: var(--font-weight__medium);
    position: relative;

    &::after,
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: 1px;
      width: 25%;
      background-color: var(--color__white)
    }

    &::after {
      left: 0
    }

    &::before {
      right: 0
    }
  }
}
</style>
